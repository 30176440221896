import { render, staticRenderFns } from "./Upload.vue?vue&type=template&id=4b9601ca&scoped=true"
import script from "./Upload.vue?vue&type=script&lang=js"
export * from "./Upload.vue?vue&type=script&lang=js"
import style0 from "./Upload.vue?vue&type=style&index=0&id=4b9601ca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b9601ca",
  null
  
)

export default component.exports