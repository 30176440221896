class AdSchedules {
  constructor() {
    this.name = ''
    this.adSeenOnSubsequentVisitOptionId = undefined
    this.timeBetweenAds = 0
    this.startOnPlaylistItem = 1
    this.repeatOnInterval = 1
    this.adManagerId = null
    this.adScheduleTypeId = null
    this.adManager = null
    this.adScheduleType = null
    this.enableBidding = false
    this.biddingTemplatePublicId = null
    this.vmapAdTag = ''
    this.skipAllAds = false
    this.adBreaks = []
    this.enableProgrammability = false
    this.adWorkflows = []
  }

  async fromData(data) {
    this.publicId = data.publicId
    this.name = data.name
    this.adSeenOnSubsequentVisitOptionId = data.adSeenOnSubsequentVisitOptionId
    this.timeBetweenAds = data.timeBetweenAds
    this.startOnPlaylistItem = data.startOnPlaylistItem
    this.repeatOnInterval = data.repeatOnInterval
    this.adManagerId = data.adManagerId
    this.adManager = data.adManager ? data.adManager : null
    this.adScheduleTypeId = data.adScheduleTypeId
    this.adScheduleType = data.adScheduleType ? data.adScheduleType : null
    this.enableBidding = data.enableBidding
    this.biddingTemplatePublicId = data.biddingTemplatePublicId || ''
    this.vmapAdTag = data.vmapAdTag || ''
    this.enableProgrammability = data?.enableProgrammability ? data.enableProgrammability : false
    this.adWorkflows = data?.adWorkflows?.length ? data.adWorkflows : []
    this.skipAllAds = data?.skipAllAds || false
  }
}

export default AdSchedules
