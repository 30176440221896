/* eslint-disable no-unused-vars */
export default [

  // Audios Routing

  {
    path: '/:projectId/audios',
    name: 'audios',
    component: () => import('@/views/audios/audio/Audios.vue'),
    meta: {
      titleText: 'Audios',
      parentText: 'Dashboard',
      contentClass: 'audios',
      navActiveLink: 'audios',
      gjIcon: 'AudioSquare',
      action: 'read',
      resource: 'video',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Audios',
          to: 'audios',
          active: true,
        },
      ],
    },
  },
  {
    path: '',
    component: () => import('@/views/audios/audio/Edit.vue'),
    children: [
      {
        path: '/:projectId/audios/:id/assets',
        name: 'audios-assets',
        component: () => import('@/views/audios/audio/tabs/assets/AssetsTable.vue'),
        meta: {
          title: 'Audios',
          titleText: 'Audios',
          parentText: 'Dashboard',
          contentClass: 'audios-assets',
          navActiveLink: 'audios',
          gjIcon: 'VideoPlayer',
          action: 'execute',
          resource: 'video',
          projectRequired: true,
          breadcrumb: [
            { text: 'Audios', to: 'audios-assets' },
            { dynamic: true, text: 'Audios', active: true },
          ],
        },
      },
      {
        path: '/:projectId/audios/:id/analytics',
        name: 'audios-analytics',
        component: () => import('@/views/audios/audio/tabs/analytics/Analytics.vue'),
        meta: {
          title: 'Audios',
          titleText: 'Audios',
          parentText: 'Dashboard',
          contentClass: 'audios-analytics',
          navActiveLink: 'audios',
          gjIcon: 'VideoPlayer',
          action: 'execute',
          resource: 'video',
          projectRequired: true,
          breadcrumb: [
            { text: 'Audios', to: 'audios-analytics' },
            { dynamic: true, text: 'Audios', active: true },
          ],
        },
      },
      {
        path: '/:projectId/audios/:id/:general?',
        name: 'audios-general-information',
        component: () => import('@/views/audios/audio/tabs/general/GeneralInformationTab.vue'),
        beforeEnter: (to, from, next) => {
          const { general } = to.params
          const validGeneralValues = ['general', undefined]

          if (!validGeneralValues.includes(general)) {
            const errorPath = '/error-404'
            next(errorPath)
          } else {
            next()
          }
        },
        meta: {
          title: 'Audios',
          titleText: 'Audios',
          parentText: 'Dashboard',
          contentClass: 'audio',
          navActiveLink: 'audios',
          gjIcon: 'VideoPlayer',
          action: 'execute',
          resource: 'video',
          projectRequired: true,
          breadcrumb: [
            { text: 'Audios', to: 'audios-general-information' },
            { dynamic: true, text: 'Audios', active: true },
          ],
        },
      },
    ],
  },

  // Podcast Routing
  {
    path: '/:projectId/podcasts',
    name: 'podcasts',
    component: () => import('@/views/audios/podcasts/Podcasts.vue'),
    meta: {
      titleText: 'Podcasts',
      parentText: 'Dashboard',
      contentClass: 'podcasts',
      navActiveLink: 'podcasts',
      gjIcon: 'IconparkMicrophone',
      action: 'read',
      resource: 'video',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Podcasts',
          to: 'podcasts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:projectId/podcasts/add',
    name: 'podcasts-add',
    component: () => import('@/views/audios/podcasts/Add.vue'),
    meta: {
      titleText: 'Create Podcast',
      parentText: 'Dashboard',
      contentClass: 'podcast',
      navActiveLink: 'podcasts',
      gjIcon: 'IconparkMicrophone',
      action: 'write',
      resource: 'video',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Podcasts',
          to: 'podcasts',
        },
        {
          text: 'New Podcast',
          active: true,
        },
      ],
    },
  },
  {
    path: '',
    component: () => import('@/views/audios/podcasts/Edit.vue'),
    children: [
      {
        path: '/:projectId/podcasts/:id/episodes',
        name: 'podcasts-episodes',
        component: () => import('@/views/audios/podcasts/tabs/Episodes.vue'),
        meta: {
          title: 'Podcasts',
          titleText: 'Podcasts',
          parentText: 'Dashboard',
          contentClass: 'podcasts-episodes',
          navActiveLink: 'podcasts',
          gjIcon: 'VideoPlayer',
          action: 'execute',
          resource: 'video',
          projectRequired: true,
          breadcrumb: [
            { text: 'Podcasts', to: 'podcasts-episodes' },
            { dynamic: true, text: 'Podcasts', active: true },
          ],
        },
      },
      {
        path: '/:projectId/podcasts/:id/analytics',
        name: 'podcasts-analytics',
        component: () => import('@/views/audios/podcasts/tabs/analytics/Analytics.vue'),
        meta: {
          title: 'Podcasts',
          titleText: 'Podcasts',
          parentText: 'Dashboard',
          contentClass: 'podcasts-analytics',
          navActiveLink: 'podcasts',
          gjIcon: 'VideoPlayer',
          action: 'execute',
          resource: 'video',
          projectRequired: true,
          breadcrumb: [
            { text: 'Podcasts', to: 'podcasts-analytics' },
            { dynamic: true, text: 'Podcasts', active: true },
          ],
        },
      },
      {
        path: '/:projectId/podcasts/:id/:general?',
        name: 'podcasts-general',
        component: () => import('@/views/audios/podcasts/tabs/General.vue'),
        beforeEnter: (to, from, next) => {
          const { general } = to.params
          const validGeneralValues = ['general', undefined]

          if (!validGeneralValues.includes(general)) {
            const errorPath = '/error-404'
            next(errorPath)
          } else {
            next()
          }
        },
        meta: {
          title: 'Podcasts',
          titleText: 'Podcasts',
          parentText: 'Dashboard',
          contentClass: 'podcast',
          navActiveLink: 'podcasts',
          gjIcon: 'VideoPlayer',
          action: 'execute',
          resource: 'video',
          projectRequired: true,
          breadcrumb: [
            { text: 'Podcasts', to: 'podcasts-general' },
            { dynamic: true, text: 'Podcasts', active: true },
          ],
        },
      },
    ],
  },
]
