/* eslint-disable no-unused-vars */
export default [
  // Ad Schedules Routing
  {
    path: '/:projectId/ad-schedules',
    component: () => import('@/views/adSchedules/AdSchedule.vue'),
    meta: {
      titleText: 'Ad Schedules',
      parentText: 'Dashboard',
      contentClass: 'ad-schedules',
      navActiveLink: 'ad-schedules',
      gjIcon: 'Money',
      breadcrumb: [
        {
          text: 'Ad schedules',
          to: 'ad-schedules',
          active: true,
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'ad-schedules',
        component: () => import('@/views/adSchedules/AdSchedules.vue'),
        meta: {
          titleText: 'Ad Schedules',
          parentText: 'Dashboard',
          contentClass: 'ad-schedules',
          navActiveLink: 'ad-schedules',
          gjIcon: 'Money',
          action: 'read',
          resource: 'ads',
          breadcrumb: [
            {
              text: 'Ad schedules',
              to: 'ad-schedules',
              active: true,
            },
          ],
        },
      },
      {
        path: ':id',
        name: 'ad-schedules-edit',
        component: () => import('@/views/adSchedules/Edit.vue'),
        meta: {
          titleText: 'Ad Schedules',
          parentText: 'Dashboard',
          contentClass: 'ad-schedule',
          navActiveLink: 'ad-schedules',
          gjIcon: 'Money',
          action: 'execute',
          resource: 'ads',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Ad schedules',
              to: 'ad-schedules',
            },
            {
              dynamic: true,
              text: 'Edit Ad Schedule',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
