import helpers from '@/services/mixins/dataAggregation'

export default {
  getIsLoading: state => state.isLoading,
  getIsUploading: state => state.isUploading,
  getAudio: state => state.audio,
  getAudios: state => state.audios,
  getPublicId: state => state.publicId,
  getTitle: state => state.audio.title,
  getDescription: state => state.audio.description,
  getAuthor: state => state.audio.author,
  getSource: state => state.audio.source,
  getLink: state => state.audio.link,
  getInsertDate: state => state.audio.insertDate,
  getPublishStartDate: state => state.audio.publishStartDate,
  getPublishEndDate: state => state.audio.publishEndDate,
  getCustomParameters: state => state.audio.customParameters,
  getStreams: state => state.streams,
  getStreamTypes: state => state.streams.map(s => s.streamType),
  getStreamsUrl: state => (state.streams.length > 0 ? state.streams[1]?.children[0]?.streamUrl : ''),
  getThumbnail: state => state.audio.thumbnail,
  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,
  getPodcasts: state => state.podcasts,
  getChapters: state => state.audio.chapters,
  getChapterState: state => state.audio.chapterState,

  // Audio Analytics
  getTopCountries: state => state.topCountries,
  getTopDevices: state => state.topDevices,
  getTopDevicesGroupedData: state => state.topDevicesGroupedData,
  getTopCountriesGroupedData: state => state.topCountriesGroupedData,
  getAudioAnalytics: state => state.audioAnalytics,
  getCustomDate: state => state.customDate,
  audioAnalyticsGropuedData: state => {
    const currentYear = new Date().getFullYear()
    let intervalDays = null
    if (state.intervalType === 5) {
      intervalDays = Math.floor(state.diffInTimeCustomDate)
    } else {
      intervalDays = helpers.methods.getIntervalDays(state.intervalType, currentYear)
    }
    return helpers.methods.aggregatedData(state.audioAnalyticsGropuedData, intervalDays, state.audioAnalytics, state.intervalType)
  },
  getAudioAnalyticsTotal: state => state.audioAnalyticsTotal,
  getTopBrowsersGroupedData: state => state.topBrowsersGroupedData,
  // Live Viewrs
  getAudioAnalyticsLiveViewrs: state => state.liveViewersGroupedData,
  getLiveViewrsTotal: state => state.liveViewersTotal,
  getAudioAnalyticsKeyMoments: state => state.keyMomentsGroupedData,
  getAudioAnalyticsKeyMomentsCards: state => state.keyMomentsCards,

}
