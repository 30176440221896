import moment from 'moment'

const helperMixin = {
  methods: {
    /**
 * Generates aggregated data based on the provided parameters.
 * @param {Object} chartData - Object containing result and date arrays.
 * @param {number} resultsLength - The desired length of the results.
 * @returns {Object} - Object containing aggregated results and dates.
 */
    aggregatedData(chartData, resultsLength, rawData, intervalType) {
      const resultData = chartData.result || []
      const dates = chartData.date || []
      let allDates = []
      let result = []

      if (resultsLength === 1) {
        return this.fillMissingHours(rawData.graphResult)
      }
      if (intervalType === 1 || intervalType === 2 || intervalType === 3 || intervalType === 4) {
        const today = new Date()
        const beforeEndDate = new Date(today)
        const olderDates = this.generateOlderDates(beforeEndDate, resultsLength)

        // Initialize result and allDates arrays
        result = Array(resultsLength).fill(0)
        allDates = olderDates

        // Populate result array based on chartData
        if (chartData.date && chartData.result && chartData.date.length > 0 && chartData.result.length > 0) {
          chartData.date.forEach((date, index) => {
          // Find the index of the date in olderDates array
            const dateIndex = olderDates.findIndex(oldDate => moment(date).isSame(moment(oldDate), 'day'))

            // If the date exists in olderDates, update the result array
            if (dateIndex !== -1) {
              result[dateIndex] = chartData.result[index]
            }
          })
        }
      } else {
        const reversedResultData = [...resultData].reverse() // Create a copy and then reverse
        const reversedDates = [...dates].reverse() // Create a copy and then reverse
        result = reversedResultData
        allDates = reversedDates
      }

      // Return aggregated results and dates
      return { results: result, dates: allDates }
    },

    /**
 * Generates an array of older dates based on the provided parameters.
 * @param {Date} oneBeforeEndDate - The date before the end date from which to generate older dates.
 * @param {number} length - The desired length of the generated dates array.
 * @returns {string[]} - An array of older dates in the form of strings.
 */
    generateOlderDates(oneBeforeEndDate, length) {
      const generatedDates = []
      const currentDate = new Date(oneBeforeEndDate)

      generatedDates.push(currentDate.toDateString())

      // Loop to generate older dates based on the provided length
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < length; i++) {
        const date = new Date(currentDate) // Create a new date object for each iteration
        date.setDate(currentDate.getDate() - i) // Adjust the date based on the iteration
        generatedDates.unshift(date.toDateString()) // Add the date to the beginning of the array
      }

      // Returns an array of older dates including today
      return generatedDates
    },

    getDaysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate()
    },

    getIntervalDays(intervalType, currentYear) {
      const today = new Date()
      const currentMonth = today.getMonth()
      let totalDays = 0

      switch (intervalType) {
      case 0:
        totalDays = 1
        break
      case 1:
        totalDays = 7
        break
      case 2:
        totalDays = this.getDaysInMonth(currentMonth, currentYear)
        break
      case 3:
        totalDays = this.getDaysInMonth(currentMonth, currentYear) + this.getDaysInMonth(currentMonth - 1, currentYear)
        break
      case 4:
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 3; i++) {
          totalDays += this.getDaysInMonth(currentMonth - i, currentYear)
        }
        break
      default:
        totalDays = 0
      }
      return totalDays
    },

    convertTimeinUnits(timeInSeconds) {
      if (timeInSeconds === null || timeInSeconds === undefined) {
        return 'N/A'
      }

      if (timeInSeconds <= 0) {
        return 'Invalid input'
      }
      if (timeInSeconds >= 1 && timeInSeconds <= 59) {
        const value = `${(timeInSeconds / 60).toFixed(2)} min`
        const percentage = `${((timeInSeconds / 60) * 100).toFixed(2)} %`
        return { value, percentage }
      } if (timeInSeconds >= 60 && timeInSeconds < 3600) {
        const value = `${(timeInSeconds / 3600).toFixed(2)} h`
        const percentage = `${((timeInSeconds / 3600) * 100).toFixed(2)} %`
        return { value, percentage }
      } if (timeInSeconds >= 3600 && timeInSeconds < 86400) {
        const value = `${(timeInSeconds / 86400).toFixed(2)} d`
        const percentage = `${((timeInSeconds / 86400) * 100).toFixed(2)} %`
        return { value, percentage }
      } if (timeInSeconds >= 86400 && timeInSeconds < 2592000) {
        const value = `${(timeInSeconds / 2592000).toFixed(2)} m`
        const percentage = `${((timeInSeconds / 2592000) * 100).toFixed(2)} %`
        return { value, percentage }
      } if (timeInSeconds >= 2592000 && timeInSeconds < 31104000) {
        const value = `${(timeInSeconds / 31104000).toFixed(2)} y`
        const percentage = `${((timeInSeconds / 31104000) * 100).toFixed(2)} %`
        return { value, percentage }
      }
      return ''
    },

    formatedDate(date) {
      if (Array.isArray(date)) {
        return date.map(d => {
          if (d) {
            if (d.includes('ago')) {
              return d
            }
            return moment.utc(d).local().format('DD/MM/YYYY')
          }
          return 'No date set!'
        })
      }
      if (date) return moment.utc(date).local().format('DD/MM/YYYY')
      return 'No date set!'
    },

    fillMissingHours(data) {
      const currentDate = new Date()
      const currentDateTime = currentDate.getTime()

      const timezoneOffset = currentDate.getTimezoneOffset()

      const filledData = Array.from({ length: 24 }, (_, index) => {
        const hourDate = new Date(new Date(currentDate).setHours(currentDate.getHours() - index, 0, 0, 0))

        hourDate.setMinutes(hourDate.getMinutes() + timezoneOffset)
        const hourIso = hourDate.toISOString()

        return {
          result: 0,
          date: hourIso,
        }
      }).reverse()

      data.forEach(item => {
        const itemDate = new Date(item.date)
        itemDate.setMinutes(itemDate.getMinutes() - timezoneOffset)
        const itemDateTime = itemDate.getTime()

        const minutesDiff = Math.floor((currentDateTime - itemDateTime) / (1000 * 60))

        if (minutesDiff >= 0 && minutesDiff < 1440) {
          const dataIndex = 24 - Math.floor(minutesDiff / 60) - 1

          if (dataIndex >= 0 && dataIndex < 24) {
            filledData[dataIndex].result = item.result
          }
        }
      })

      const results = filledData.map(item => item.result)
      const dates = filledData.map(item => {
        const minutesDiff = Math.floor((currentDateTime - new Date(item.date).getTime()) / (1000 * 60))
        if (minutesDiff < 60) return 'Just now'
        if (minutesDiff <= 1499) return `${Math.floor(minutesDiff / 60)} hours ago`
        return 'over 24 hours ago'
      })

      return { results, dates }
    },

    formatHoursAgo(date) {
      const currentDate = new Date()
      const timeDifference = currentDate - date
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60))

      if (hoursDifference < 1) return 'Just now'
      if (hoursDifference === 1) return '1 hour ago'
      if (hoursDifference >= 24) return `${hoursDifference} hours ago`
      return `${hoursDifference} hours ago`
    },

  },

}

export default helperMixin
