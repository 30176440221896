// import store from '@/store'
import { actions, state } from './state/store'

async function encode(file, requestKey) {
  file.status = 'encoding'
  state.eventBus.$emit(state.eventBusName, state.uploads)

  const encodeHeader = {
    downloadUrl: requestKey,
  }

  if (file.fileType === 'video') {
    await actions.encodesVideo(encodeHeader)
      .then(r => {
        file.status = 'success'
        file.mediaId = r.data.result.name

        state.eventBus.$emit(state.eventBusName, state.uploads)
      })
      .catch(() => {
        file.status = 'failed'
        file.failedStatus = 'encode'

        state.eventBus.$emit(state.eventBusName, state.uploads)
      })
  }
  if (file.fileType === 'audio') {
    await actions.encodesAudio(encodeHeader)
      .then(r => {
        file.status = 'success'
        file.mediaId = r.data.result.publicId

        state.eventBus.$emit(state.eventBusName, state.uploads)
      })
      .catch(() => {
        file.status = 'failed'
        file.failedStatus = 'encode'

        state.eventBus.$emit(state.eventBusName, state.uploads)
      })
  }
}

export default encode
