import BidParamValues from './bidParamValues'

class BiddingTemplateBidders {
  constructor() {
    this.bidderId = null
    this.biddingTemplatePublicId = null
    this.bidderAlias = ''
    this.value = ''
    this.id = null
    this.name = ''
    this.logoPath = ''
    this.bidderLogoPath = ''
    this.notification = ''
    this.bidParamValues = []
    this.isDeleted = false
  }

  fromData(data) {
    this.bidderId = data.bidderId ? data.bidderId : null
    this.biddingTemplatePublicId = data.biddingTemplatePublicId ? data.biddingTemplatePublicId : null
    this.id = data.id ? data.id : 0
    this.bidderAlias = data.bidderAlias ? data.bidderAlias : ''
    this.value = data.value ? data.value : ''
    this.name = data.name ? data.name : ''
    this.logoPath = data.logoPath ? data.logoPath : ''
    this.bidderLogoPath = data.bidderLogoPath ? data.bidderLogoPath : ''
    this.notification = data.notification ? data.notification : ''
    this.isDeleted = data.isDeleted ? data.isDeleted : false
    this.bidParamValues = data?.bidParamValues ? data.bidParamValues.map(x => {
      x.biddingTemplateBidderId = data.bidderId ? data.bidderId : null
      const bidParamValue = new BidParamValues()
      bidParamValue.fromData(x)
      return bidParamValue
    }) : null
  }
}

export default BiddingTemplateBidders
