/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import organization from '@/store/account/organization/moduleOrganization'

const livestreamUrl = process.env.VUE_APP_API_LIVESTREAM

export default {

  getProjects({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${organization.state.activeOrganizationId}/projects`, state.filters)
        .then(response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_PROJECTS', response.data.result.items)

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getProjectsOfUser({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v2/accounts/organizations/${organization.state.activeOrganizationId}/projects`, state.dropdownFilters)
        .then(response => {
          commit('SET_PROJECTS_OF_USER', response.data.result.items)
          commit('SET_PINNED_PROJECTS', response.data.result.items.filter(e => e.orderNumber === 0))
          commit('SET_UNPINNED_PROJECTS', response.data.result.items.filter(e => e.orderNumber !== 0))
          commit('SET_PAGINATION', response.data.result)
          resolve(response)
        })
        .catch(error => { resolve(error) })
    })
  },
  getProjectsOfUserAll({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/accounts/organizations/${organization.state.activeOrganizationId}/projects/select-list`)
        .then(response => {
          commit('SET_PROJECTS_SELECT_LIST', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getAllProjectsOfUser({ commit, state }) {
    state.filters.params.organizationId = localStorage.getItem('organizationId')
    return new Promise((resolve, reject) => {
      axios.get('/api/accounts/organizations/project', state.filters)
        .then(response => {
          // if (!state.activeProjectId) {
          //   commit('SET_ACTIVE_PROJECT', response.data.result.items[0])
          //   commit('UPDATE_IS_DROPDOWN_LOADING', false)
          // }
          commit('SET_PROJECTS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { resolve(error) })
    })
  },

  getProject({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${id}`)
        .then(response => {
          commit('SET_PROJECT_ONEDIT', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getProjectWithProjectId({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${id}`)
        .then(response => {
          commit('SET_ACTIVE_PROJECT', response.data.result)
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },

  getProjectEncodingTemplates({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/encoding-templates/select-list')
        .then(response => {
          commit('SET_ENCODING_TEMPLATES', response.data.result)
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },

  getReleaseChannel({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`api/projects/${state.activeProjectId}/players/options/release-channel`)
        .then(response => {
          commit('SET_RELEASE_CHANNEL', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  addProject({ commit }, project) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/organizations/${organization.state.activeOrganizationId}/projects`, project)
        .then(response => {
          commit('ADD_PROJECT', Object.assign(project, { id: response.data.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateProject({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${data.publicProjectId}`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removeProjectById({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${itemId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removeProjectUser({ state }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${state.activeProjectId}/users/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateDefaultProject({ commit, state }, projectId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/accounts/projects/${projectId}/default`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // getVideoCatalogUrl({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`/api/projects/${state.activeProjectId}/videos/catalog`)
  //       .then(response => {
  //         commit('SET_PROJECT_VIDEOCATALOG_URL', response.data.result)
  //         resolve(response)
  //       })
  //       .catch(error => { reject(error) })
  //   })
  // },

  toggleProjectGeoBlocking({ commit, state }, enabled) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${state.activeProjectId}/geoBlocking?enabled=${enabled}`)
        .then(response => {
          commit('UPDATE_ACTIVE_PROJECT_PROP', { key: 'geoBlockingEnabled', value: true })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getProjectsCustomScreens({ commit, state }, publicId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${livestreamUrl}api/v1/${state.activeProjectId}/custom-screens`)
        .then(async response => {
          commit('SET_PROJECTS_CUSTOMSCREENS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateProjectsOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/organizations/${organization.state.activeOrganizationId}/projects/order`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
