import Audio from '@/models/audios/audio'

export default {
  loadingAudios: false,
  loadingAudio: false,
  isUploading: false,
  newAudio: false,
  audios: [],
  audio: new Audio(),
  streams: [],
  reloadPage: false,

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },

  filters: {
    params: {
      folderView: false,
      folderId: null,
      insertedDate: null,
      key: null,
      value: null,
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },

  audioLoaded: false,
  podcasts: [],
  // Audio Analytics data
  audioAnalyticsTotal: 0,
  liveViewersTotal: 0,
  audioAnalytics: [],
  keyMoments: [],
  keyMomentsCards: [],
  topCountries: [],
  topDevices: [],
  topBrowsers: [],
  customDate: [],
  dateStr: '',
  topBrowsersGroupedData: {},
  keyMomentsGroupedData: {},
  audioAnalyticsGropuedData: {},
  liveViewersGroupedData: {},
  topCountriesGroupedData: {},
  topDevicesGroupedData: {},
  // Audio Analytics loaders
  loadingAnalytics: false,
  loadingTopBrowsers: false,
  loadingTopCountries: false,
  loadingTopDevices: false,
  loadingLiveViewers: false,
  loadingKeyMoments: false,
  loadingKeyMomentsCards: false,
  loadingCustomAnalytics: false,
  loadingAnalyticsReport: false,
  analyticsReport: null,
}
