/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@/libs/axios'
import project from '@/store/account/project/moduleProject'

const baseURL = process.env.VUE_APP_API_LIVESTREAM
const analyticsUrl = process.env.VUE_APP_API_ANALYTICS_URL
const version = 1

export default {
  // ////////// //
  /* Live Videos CRUD */

  /* Get Live Videos - PagedList */
  getLiveVideos({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/${project.state.activeProjectId}/live-videos`, { baseURL, params: state.filters.params })
        .then(async response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_LIVEVIDEOS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  /* Create a new Live video */
  postLiveVideo({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/live-videos`,
        baseURL,
        method: 'POST',
        data,
      })

        .then(async response => {
          // commit('SET_LIVEVIDEO', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  /* Update Existing Live Video */
  updateLiveVideo({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/live-videos`,
        baseURL,
        method: 'PUT',
        data,
      })
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  /* Get Live Video Details */
  getLiveVideo({ commit, state }, videoId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v${version}/${project.state.activeProjectId}/live-videos/${videoId}`, { baseURL })
        .then(async response => {
          commit('SET_LIVEVIDEO', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeVideo({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${baseURL}api/v${version}/${project.state.activeProjectId}/live-videos/${itemId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  /* Update Live video Thumbnail */
  updateLiveVideoThumbnail({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/live-videos/thumbnail`,
        baseURL,
        method: 'POST',
        data,
      })
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  /* Change VideoStatus */
  goLive({ commit, state }, publicId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/live-videos/go-live`,
        baseURL,
        method: 'PUT',
        params: { publicId },
      })

        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  /* Cut And Publish Preview */
  cutPreview({ commit, state }, videoId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/live-videos/${videoId}/cut-preview`,
        baseURL,
        method: 'POST',
      })

        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  /* Cut And Publish */
  cutPublish({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/live-videos/cut-and-publish`,
        baseURL,
        method: 'POST',
        data,
      })

        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  /* Stop and Cut */
  stopCut({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/live-videos/stop-and-cut`,
        baseURL,
        method: 'POST',
        data,
      })

        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  /* Stop without saving */
  stopWithoutSaving({ commit, state }, videoId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/live-videos/${videoId}/stop`,
        baseURL,
        method: 'POST',
      })

        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  liveVideoCustomScreens({ commit, state }, publicId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v${version}/${project.state.activeProjectId}/live-videos/${publicId}/custom-screens`)
        .then(async response => {
          commit('SET_LIVEVIDEO_CUSTOMSCREENS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  addLiveVideoCustomScreen({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/custom-screens`,
        baseURL,
        method: 'POST',
        data,
      })
        .then(response => {
          commit('ADD_CUSTOM_SCREEN', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  customScreensSelectList({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/custom-screens/select-list`, { params: { 'api-version': version } })
        .then(async response => {
          commit('SET_CUSTOMSCREENS_SELECTLIST', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  // Get embed scripts of livestream video
  getEmbed({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/video/embed/${data.player}/${data.id}/${data.seo}`)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  changeActiveScreen({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/live-videos/active-screens`,
        baseURL,
        method: 'PUT',
        data,
      })
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  switchLiveVideoChannel({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/live-videos/switch-channel`,
        baseURL,
        method: 'POST',
        data,
      })
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // Analytics
  getVideoAnalytics({ commit, state }, query) {
    const { videoId, data } = query

    let endpoint = ''
    if (videoId === undefined) {
      endpoint = `${analyticsUrl}api/v2/projects/${project.state.activeProjectId}/video/analytics/graph?metric=${data.metric}&intervalType=${data.intervalType}&isLive=${true}`
    } else {
      endpoint = `${analyticsUrl}api/v2/projects/${project.state.activeProjectId}/video/analytics/graph/?videoId=${videoId}&metric=${data.metric}&intervalType=${data.intervalType}&isLive=${true}`
    }

    let differenceInDays = null

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      commit('LOADING_CUSTOM_ANALYTICS', true)
      const [from, to] = state.dateStr.split(' to ')
      const utcDates = data.customDate.map(dateString => new Date(dateString))
      const startDate = utcDates[0]
      const endDate = utcDates[1]
      const differenceinTime = endDate.getTime() - startDate.getTime()
      differenceInDays = differenceinTime / (1000 * 3600 * 24)
      differenceInDays = 5
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS', true)

    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(response => {
          const result = response?.data?.result
          commit('SET_ANALYTICS', { result, intervalType: query.data.intervalType, differenceInDays })
          resolve(response)
        })
        .catch(error => {
          commit('SET_ANALYTICS', [])
          console.error('Error', error)
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS', false)
          commit('LOADING_CUSTOM_ANALYTICS', false)
        })
    })
  },

  getTotalClips({ commit, state }, query) {
    const { videoId, pagination } = query
    commit('LOADING_TOTAL_CLIPS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/videos?parentVideoId=${videoId}&page=${pagination.page}&take=${pagination.take}`)
        .then(async response => {
          commit('SET_TOTAL_CLIPS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('LOADING_TOTAL_CLIPS', false)
        })
    })
  },

  getVideoAnalyticsTopCountries({ commit, state }, query) {
    const { videoId, data } = query
    let endpoint = ''

    if (videoId === undefined) {
      endpoint = `${analyticsUrl}api/v2/projects/${project.state.activeProjectId}/analytics/topCountries?intervalType=${data.intervalType}&isLive=${true}`
    } else {
      endpoint = `${analyticsUrl}api/v2/projects/${project.state.activeProjectId}/analytics/topCountries?videoId=${videoId}&intervalType=${data.intervalType}&isLive=${true}`
    }

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_COUNTRIES', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_COUNTRIES', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_COUNTRIES', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_COUNTRIES', false)
        })
    })
  },

  getVideoAnalyticsTopDevices({ commit, state }, query) {
    const { videoId, data } = query

    let endpoint = ''
    if (videoId === undefined) {
      endpoint = `${analyticsUrl}api/v2/projects/${project.state.activeProjectId}/analytics/topDevices?intervalType=${data.intervalType}&isLive=${true}`
    } else {
      endpoint = `${analyticsUrl}api/v2/projects/${project.state.activeProjectId}/analytics/topDevices?videoId=${videoId}&intervalType=${data.intervalType}&isLive=${true}`
    }
    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_DEVICES', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_DEVICES', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_DEVICES', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_DEVICES', false)
        })
    })
  },

  getVideoAnalyticsTopBrowsers({ commit, state }, query) {
    const { videoId, data } = query

    let endpoint = ''
    if (videoId === undefined) {
      endpoint = `${analyticsUrl}api/v2/projects/${project.state.activeProjectId}/analytics/topBrowsers?intervalType=${data.intervalType}&isLive=${true}`
    } else {
      endpoint = `${analyticsUrl}api/v2/projects/${project.state.activeProjectId}/analytics/topBrowsers?videoId=${videoId}&intervalType=${data.intervalType}&isLive=${true}`
    }
    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_BROWSERS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_BROWSERS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_BROWSERS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_BROWSERS', false)
        })
    })
  },

  getVideoAnalyticsLiveViewrs({ commit, state }, query) {
    const { videoId, data } = query
    let endpoint = ''

    if (videoId === undefined) {
      endpoint = `${analyticsUrl}api/v2/projects/${project.state.activeProjectId}/analytics/liveViewers?isLive=${true}`
    } else {
      endpoint = `${analyticsUrl}api/v2/projects/${project.state.activeProjectId}/analytics/liveViewers?videoId=${videoId}&isLive=${true}`
    }

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_LIVE_VIEWERS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_LIVE_VIEWERS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_LIVE_VIEWERS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_LIVE_VIEWERS', false)
        })
    })
  },
  getLiveVideoAnalyticsReport({ commit, state }, query) {
    const { videoId, data } = query
    let endpoint = `${analyticsUrl}api/v2/projects/${project.state.activeProjectId}/video/analytics/download/xls/live?videoId=${videoId}&isLive=true&intervalType=${data.intervalType}`

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_REPORT', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_ANALYTICS_REPORT', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_ANALYTICS_REPORT', null)
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_REPORT', false)
        })
    })
  },
  setGeoBlockingRule({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/live-videos/geo-blocking-rules`,
        baseURL,
        method: 'PUT',
        data,
      })
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getSecureLink({ commit, state }, videoId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v${version}/${project.state.activeProjectId}/live-videos/${videoId}/secure-link`, { baseURL })
        .then(async response => {
          commit('SET_SECURE_LINK', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
