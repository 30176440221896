<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
    style="fill: currentcolor;"
  >
    <path
      d="M4.5 8.75C2.16 8.75 0.25 6.84 0.25 4.5C0.25 2.16 2.16 0.25 4.5 0.25C6.84 0.25 8.75 2.16 8.75 4.5C8.75 6.84 6.84 8.75 4.5 8.75ZM4.5 1.75C2.98 1.75 1.75 2.98 1.75 4.5C1.75 6.02 2.98 7.25 4.5 7.25C6.02 7.25 7.25 6.02 7.25 4.5C7.25 2.98 6.02 1.75 4.5 1.75Z"
      fill="white"
    />
    <path
      d="M4.5 19.75C2.16 19.75 0.25 17.84 0.25 15.5C0.25 13.16 2.16 11.25 4.5 11.25C6.84 11.25 8.75 13.16 8.75 15.5C8.75 17.84 6.84 19.75 4.5 19.75ZM4.5 12.75C2.98 12.75 1.75 13.98 1.75 15.5C1.75 17.02 2.98 18.25 4.5 18.25C6.02 18.25 7.25 17.02 7.25 15.5C7.25 13.98 6.02 12.75 4.5 12.75Z"
      fill="white"
    />
    <path
      d="M7.65017 14.73C7.42017 14.73 7.20017 14.63 7.05017 14.43C6.80017 14.1 6.87019 13.63 7.20019 13.38L20.5502 3.40003C20.8802 3.15003 21.3502 3.22003 21.6002 3.55003C21.8502 3.88003 21.7802 4.35003 21.4502 4.60003L8.10018 14.58C7.97018 14.68 7.81017 14.73 7.65017 14.73Z"
      fill="white"
    />
    <path
      d="M21.0002 16.72C20.8402 16.72 20.6902 16.67 20.5502 16.57L7.20019 6.59002C6.87019 6.34002 6.80017 5.87002 7.05017 5.54002C7.30017 5.21002 7.77018 5.14002 8.10018 5.39002L21.4502 15.37C21.7802 15.62 21.8502 16.09 21.6002 16.42C21.4502 16.61 21.2302 16.72 21.0002 16.72Z"
      fill="white"
    />

  </svg>
</template>
